<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterPrintTitles">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <app-input v-model="filter.title" id="filter_title"
                         :label="$t('printTitle.filter.title')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.name" id="filter_slug"
                         :label="$t('printTitle.filter.name')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.id" id="filter_id"
                         :label="$t('printTitle.filter.id')"></app-input>
            </div>
            <div class="col-lg-3 col-md-6">
              <app-select
                v-model="filter.site"
                :options="sites"
                :label="$t('printTitle.filter.site')"
                id="filter_site"
                disable-form-group
              >
              </app-select>
            </div>

            <div class="col-lg-3">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('printTitle.filter.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import PrintTitleFilter from '../../model/PrintTitleFilter'

export default {
  name: 'PrintTitleFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(PrintTitleFilter)
    }
  },
  computed: {
    sites () {
      return this.$store.getters['site/enabledSites']()
    }
  },
  components: {
    appInput: Input,
    appSelect: Select
  },
  methods: {
    filterPrintTitles () {
      this.$store.commit('printTitle/setPage', 1)
      this.$store.commit('printTitle/setFilter', this.filter)
      this.$store.dispatch('printTitle/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(PrintTitleFilter)
      this.$store.commit('printTitle/setFilter', this.filter)
      this.$store.dispatch('printTitle/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['printTitle/filter']
  }
}
</script>

<style lang="scss">

</style>
